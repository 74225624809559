.page-node-40 {
  .node-webform {
    form > div {
      display: flex;
      flex-wrap: wrap;
    }
    .content {
      padding: 0 !important;

      h4 {
        font-weight: 400;
        text-align: left;
      }

      .webform-client-form {
        text-align: left;


        .webform-component {
          width: 90%;
          float: left;
          padding: 0em 5px;

          @include media($bp-tablet) {
            width: 50%;
          }

          @include media($bp-desktop) {
            padding: 0em 1em;

            &.webform-component-node-reference {
              padding: 0px;
            }
          }

          label {
            font-size: 0.888em;
          }

          input {
            width: 100%;
            margin-bottom: 1em;
            padding: 0.3em 5%;
          }

          &.webform-component-markup {
            width: 100%;

            p {
              font-size: 0.888em;
            }
          }
        }

        fieldset {
          padding: 0em;
          margin: 0em 5% 2em;
          flex: 1 100%;
          border: 1px solid color("orange");

            .form-item {
              
              .form-checkbox {
                width: 25px;
              }
            }

          @include media($bp-tablet) {
            flex: 1 48%;
            max-width: 48%;
            margin: 0em 1% 2em;
          }

          @include media($bp-desktop) {
            flex: 1 30%;
            max-width: 30%;
          }

          .fieldset-wrapper {
            padding: 0 1em 1em;

            .form-item {
              width: 100%;

              input {
                margin-bottom: 0;
              }
            }
          }

          .webform-component > legend {
            display: none;
          }

          legend {
            width: 100%;
            background-color: color("white");
            border: 1px solid color("orange");
            padding: 0.5em;

            .fieldset-legend {
              position: relative;
              background-image: none;

              span.summary {
                display: none;
              }

              .fieldset-title {
                color: color("black");
                display: block;
                font-size: 0.888em;
                font-weight: 900;
              }
            }
          }
        }

        .webform-component-fieldset {
          legend {
            position: relative;
            &:after {
              font-family: FontAwesome;
              content: "\f056";
              pointer-events: none;
              color: color("black");
              position: absolute;
              right: 0.5em;
              top: 0.5em;
            }
          }

          &.collapsed {
            legend {
              &:after {
                content: "\f055";
                pointer-events: none;
              }
            }
          }
        }

        .form-actions {
          width: 100%;
          text-align: center;

          input {
            padding: 0.5em 2.5em;
          }
        }
      }
    }
  }
}