.node-type-markets {
  #main {
    display: flex;
    flex-direction: column;
    width: 95%;
    margin: 0 auto;
    padding: 1em 0 3em;

    @include media($bp-tablet) {
      flex-direction: row;
      width: 90%;
    }

    @include media($bp-lgdesktop) {
      width: 85%;
    }

    #content {
      flex: 1 75%;

      @include media($bp-tablet) {
        padding-right: 4em;
      }

      .region-content {
        text-align: left;
      }
    }

    #sidebar-second {
      flex: 1 25%;
      padding-top: 3em;

      @include media($bp-tablet) {
        padding-top: 0;
      }

      .region-sidebar-right {
        width: auto;
        text-align: center;

        h5 {
          margin: 0;

          p {
            margin: 0;
          }
        }
      }
    }
  }
}
