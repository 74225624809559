body, html {
  overflow-x: hidden;
}

img {
	max-width: 100%;
	height: auto;
}

#first-time {
	display: none;
	visibility: hidden;
}

 // DELETE THIS BEFORE LAUNCH
 .messages.error {
    display: none; 
    visibility: hidden;
 }