.find-my-rep {
	background-image: url("../../../../../default/files/black-texture.jpg");
	background-size: cover;
    background-position: center center;
    padding: 1.5em 0em;

    @include media($bp-smdesktop) {
    	text-align: left;
    	padding: 1em 2em 2em;
    }

    .content {
    	@include media($bp-desktop) {
    		width: 1000px;
    	}
    }

	h2 {
		margin-top: 0em;
	}
}