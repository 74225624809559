ol, ul {
  text-align: left;

  @include media ($bp-smdesktop) {
  	padding-left: 1em;
  }
  
  li {
    margin-bottom: 1em;
  }
}