.page-products-product-finder {
  #main-wrapper {
    max-width: 1416px;
    min-width: 320px;
    margin: 0 auto;
  }

  .region-sidebar-left {
    .block-facetapi {
      margin: 0 1em;
      border: 1px solid color("gray");
      font-size: 0.89em;

      &.expanded h2 {
        &:after {
          content: "\f056";
          color: color("black");
          font-family: FontAwesome;
          float: right;
        }
      }
    }

    h2 {
      text-transform: uppercase;
      font-size: 0.89em;
      color: color("darkgray");
      font-weight: 900;
      margin: 0 1em;

      &:after {
        content: "\f055";
        color: color("black");
        font-family: FontAwesome;
        float: right;
      }
    }

    ul {

      li {
        list-style: none;
        margin-left: 0;

        .facetapi-facet {
          padding-left: 1em;
        }

        .facetapi-active {
          background-color: color("orange");
          color: color("white");
        }

        .facetapi-inactive {
          a {
            color: color("darkgray");
          }
        }
      }
    }
  }

  .view-product-finder {
    display: flex;
    flex-wrap: wrap;
    margin-top: 2em;

    .views-row {
      min-height: 260px;
      width: 50%;
      text-align: center;
      float: left;
      border-top: 1px solid color("gray");

      &.views-row-odd {
        clear: both;
      }      

      h5 {
        margin-top: 0;

        p {
          color: color("black");
          text-transform: none;
        }
      }



      @include media($bp-smdesktop) {
        width: 32%;
        min-height: 310px;

        &.views-row-odd {
          clear: none;
        }

        &:nth-child(3n-1) {
          margin: 0em 2%;
        }
      }

      @include media($bp-lgdesktop) {
        width: 315px;
        height: 350px;

        .views-field-field-featured-image {
          margin-bottom: -15px;
        }
      }
    }
  }
}