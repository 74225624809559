#main-wrapper {
	margin: 0 auto;

	h1#page-title {
		display: none;
		visibility: hidden;
	}

	.region-content {
		text-align: center;

		.block {
			margin-top: 2em;
			margin-bottom: 2em;

			&.find-my-rep {
				margin: 0em;
			}
		}

		.content, .view, .blockgroup {
			min-width: 320px;
			max-width: 1416px;
			margin: 0 auto;
			padding: 0px 15px;	
		}

		@include media($bp-tablet) {
			.site-map {
				margin-top: 3em;
			}
		}
	}	
}








