#node-92 {
	@include media($bp-tablet) {
		p {
			text-align: left;
		}

		.left {
			p {
				text-align: center;
			}
		}
	}

	@include media($bp-desktop) {
		.left {
			float: left;
			width: 20%;
			padding-right: 1em;
		}

		.right {
			float: right;
			width: 80%;
			text-align: left;
		}
	}
}