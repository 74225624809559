.view-id-Manuals {
	.view-content {
		display: inline-flex;
		flex-wrap: wrap;
		align-items: flex-start;

		.grid-four {
			margin: 0.75em 0em;
		}

		h2 {
			font-weight: bold;
			text-transform: capitalize;
			width: 100%;
			color: color("gray");
			margin-bottom: 1em;

			@include media($bp-desktop) {
				text-align: left;
			}
		}

		h5 {
			margin: 0em;

			@include media($bp-smdesktop) {
				margin-top: 0em;
			}
		}

		p.button {
			color: color("orange");
		}
	}	
}