/* =Typography
----------------------------------------------- */
body, html {
  font-size: 18px;
  line-height: 26px;
}

// color definitions
.typography.light {
  color: get-color("black");

  a {
    @extend .w-bold;
    color: get-color(orange);
    text-decoration: none;
  }

  a:hover {
    color: get-color(black);
  }

  h1, h2, h2.alt, h3, h4, h5, h6 {
    color: color("black");
  }
}

.typography.dark {
  color: color("white");

  a {
    color: color("orange");
  }

  a:hover {
    color: color("gray");
  }

  h1, h2, h3, h4, h5, h6 {
    color: color("orange");
  }

  h2.alt {
    color: color("white");
  }

  p {
  	color: color("white");
  }
}

// font styling and sizing
p, small, h1, h2, h3, h4, h5, h6, li, svg text, a {
  color: inherit;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


small {
  display: block;
  font-size: modular-scale(-2);
}

h1, h2, h3, h4, h5, h6, .head-seven, .copyright {
  font-family: $header-font;
}

h1 {
  font-size: 1.75em;
  font-weight: 600;
  line-height: 1em;
  text-transform: uppercase;
  margin: 0em;

  @include media($bp-tablet) {
    font-size: 2em;
    line-height: 1.333em;
  }

  @include media($bp-smdesktop) {
    font-size: 2.222em;
    line-height: 2.333em;
  }
}

h2 {
  font-size: 2em;
  font-weight: 400;
  line-height: 1.2em;
  margin-bottom: 0em;

  @include media($bp-smdesktop) {
    font-size: 2.222em;
    line-height: 2.333em;
    margin-top: 0em;
  }
}

.block-webform h2 {
  font-size: 0.888em;
  font-weight: bold;
  line-height: 1.222em;
}

.chronicle h2 {
  @include media($bp-smdesktop) {
    font-size: 2em;
    font-weight: bold;
    line-height: 1em;
  }
}


h3 {
  font-size: 1.367em;
  font-weight: 600;
  line-height: 1.644em;

  @include media ($bp-smdesktop) {
    font-size: 1.667em;
    font-weight: 600;
    line-height: 1.944em;
  }
}

h4, .views-field-field-prime-mover-assign .views-label,
.views-field-field-markets .views-label {
  font-size: 1.25em;
  font-weight: 600;
  line-height: 1.533em;

  @include media($bp-smdesktop) {
    font-size: 1.3em;
    line-height: 1.58em;
  }

  @include media($bp-lgdesktop) {
    font-size: 1.556em;
    line-height: 1.833em;
  }
}

h5 {
  font-size: 1em;
  font-weight: 600;
  line-height: 1.2em;
  text-transform: uppercase;

  @include media($bp-smdesktop) {
    font-size: 1.111em;
    line-height: 1.333em;
  }
}

h6 {
    font-size: 1em;
    font-weight: 400;
    line-height: 1.444em;
}

.head-seven, .block-addtoany h2, .node-news-article .field-name-field-publish-date span {
  font-size: 0.888em;
  font-weight: 400;
  line-height: 1.222em;
}

.copyright {
  font-size: 0.777em;
  font-weight: 400;
  line-height: 1em;

  a {
    color: color("lightgray");
  }
}

p, li, a {
  font-family: $body-font;
  font-size: 1em;
  line-height: 1.444em;
}

p {
  margin: 1em 0em;

  &.button {
    font-family: $button-font;
    font-size: 1em;
    font-weight: 700;
    line-height: 1em;
  }
}

a {
  color: color("orange");
  text-decoration: none;
}


.banner-alt {
  background-color: color("darkgray");

  h2 {
    font-size: 2em;
    font-weight: 700;
    line-height: 1em;
    text-transform: uppercase;
    color: color("orange");

    @include media($bp-smdesktop) {
      font-size: 4.166em;
      line-height: 1.333em;
    }
  }

  p {
  font-size: 0.888em;
  font-weight: 700;
  line-height: 1.2em;
  color: color("white");

  @include media ($bp-tablet){
    font-size: 1em;
  }

  @include media($bp-smdesktop) {
    font-size: 1.666em;
    line-height: 2.333em;
    }
  }
}

// Quicktabs (Front page News & Social)
.block-quicktabs li a {
  font-size: 1.25em;
  font-weight: bold;
  line-height: 1.533em;

  @include media($bp-smdesktop) {
    font-size: 1.3em;
    line-height: 1.58em;
  }

  @include media($bp-lgdesktop) {
    font-size: 1.556em;
    line-height: 1.833em;
  }

}

// Uppercase links (Example: Front page News / Events links)
a.read-more {
  font-size: 0.888em;
  font-weight: bold;
  text-transform: uppercase;
}

// Products Title 
.node-type-products h1 {
  text-transform: none;

  p {
    margin: 0em;
  }
}

// Products Prime Mover titles 
.node-prime-mover .field-name-title-field {
  font-family: $button-font;
  font-size: 1em;
  font-weight: 700;
  line-height: 1em;
  margin-left: 0.5em;
  vertical-align: middle;
}

.no-margin {
  line-height: 1em;
  margin-top: 0;
  margin-bottom: 0;
}

.no-line-height {
  line-height: 0;
}

// positional helper classes
.text-center { text-align: center; }
.text-left   { text-align: left; }
.text-right  { text-align: right; }
.text-middle {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

// text styling helper classes
.w-xlight    { font-weight: 200; }
.w-light     { font-weight: 300; }
.w-regular   { font-weight: 400; }
.w-semibold  { font-weight: 600; }
.w-bold      { font-weight: 700; }
.w-xbold     { font-weight: 800; }
.uppercase   { text-transform: uppercase; }
.lowercase   { text-transform: lowercase; }
.capitalize  { text-transform: capitalize; }
.italic      { font-style: italic; }
.underline   { text-decoration: underline; }

// super / sub script
.sub-script {
  position: relative;
  top: 4px;
  font-size: 60%;
}

// shadows
.text-shadow {
  text-shadow: 0px 5px 12px rgba(0, 0, 0, 0.45);
}

.text-shadow-alt {
  text-shadow: 0px 5px 8px rgba(0, 0, 0, 0.45);
}
