// Front mailing list
.mailing-list {
    width: 100%;
    text-align: center;
	color: color("white");
    background-image: url("../../../../../default/files/mailing-list-bkg.jpg");
    background-size: cover;
    background-position: center center;
    padding: 2em;
    
    h2 {
    	font-weight: bold;
    }    

    #node-11 h2 {
    	display: none;
    	visibility: hidden;
    }

    .form-email {
    	width: 250px;
    	padding-left: 5px;

    	@include media ($bp-tablet) {
    		width: 100%;
    	}
    }

    a {
        font-weight: bold;
        color: color("white");
        text-decoration: underline;
    }
}