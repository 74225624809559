.page-user {
	#main-wrapper {
		#content {
			min-width: 320px;
			max-width: 750px;
			margin: 0 auto;
			padding-top: 1em;
		}

		.tabs {
			text-align: center;
			margin: 0 auto;

			ul {
				display: inline-flex;
				flex-wrap: wrap;

				li {
					margin: 0em;
					padding-bottom: 0.5em;
				}
			}
			
		}

		@include media($bp-desktop) {
			padding-top: 2em;
		}
	}
}