#page-wrapper {
  padding-top: 124px; //7em;
  margin-top: -1.5em;

  @include media($bp-tablet){
    padding-top: 120px;
  }

  @include media($bp-smdesktop){
    padding-top: 115px;
  }

  @include media($bp-desktop){
    padding-top: 126px;
  }


  .front & {
    margin-top: 0;
  }

  transition: padding ease 0.3s;

  &.search_bar_open {
    padding-top: 9em;

    #header {
      top: 0;
    }
  }
}

.container {
  max-width: 1416px;
  min-width: 320px;
  margin: 0 auto;
}

/* Header */
#header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: fixed;
  background-color: color("white");
  color: color("black");
  height: 9em;
  border-bottom: 1px solid color("orange");
  z-index: 154;
  top: -44px;
  transition: top ease 0.3s;

  @include media($bp-tablet) {
    height: 9em;
    top: -48px;
  }

  @include media($bp-smdesktop) {
    top:-53px;
  }

  @include media($bp-desktop) {
    top: -60px;
    height: 10em;
  }


  .pre_nav {
    flex: 1 100%;
  }
  #subnav {
    background-color: color("lightgray");
    color: color("orange");
    width: 100%;
    height: 60px; //51px;
    position: relative;
    flex: 1 100%;

    @include media($bp-desktop){
      height: 70px;
      padding-top: 30px;
    }

    .region-subnav {
      float: right;
      padding-right: 5%;
      height: 60px; //44px;

      @include media($bp-desktop){
        height: 70px;

        .block-gtranslate, .block-menu, .search_toggle_block {
          height: 70px;
        }

        .block-system.block-menu {
          margin-top: 10px;
          padding: 0px 0.5em;

          ul li {
            padding: 0em;
          }
        }

        .search_toggle_block {
          width: 30px;
        }

      }

      .block-menu a {
        color: color("orange");
        text-transform: uppercase;
        font-size: 0.778em;
      }
    }
  }

  .nav-wrapper {
    flex: 1 100%;
    display: flex;
    align-items: center;
    height: 38%;
  }


  #logo {
    padding-left: 1em;
    flex: 1 70%;
    width: 125px;

    @include media($bp-tablet) {
      flex: 1 75%;
    }


    @include media($bp-desktop) {
      flex: 1 20%;
      margin-top: 0;
      margin-left: 0;
      padding-left: 0;

    }
  }
  .region-nav {
    flex: 1 0;

    @include media($bp-desktop) {
      flex: 1 80%;
    }
    .block-menu {
      flex: 1 0;
    }
    .phone-number {
      flex: 1 20%;
      font-weight: bold;
      color: color("orange");
      text-align: center;
      padding-right: 1em;

      p {
        margin: 0;
        font-size: 1.5em;
      }
    }
  }

  .main-logo {
    width: 6em;

    @include media($bp-tablet) {
      width: 11em;
    }

    @include media($bp-desktop) {
      width: 15em;
      padding-left: 35px;
    }

    @include media($bp-lgdesktop) {
      width: 15em;
      padding-left: 0em;
    }
  }

  .region {
    display: flex;
    align-items: center;
  }

  .block-menu {
    display: flex;
    >h2 {
      display: none;
    }
    .content {
      width: 100%;
    }
    .menu {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0;
      padding: 0;
      line-height: 3em;
      font-size: 0.889em;
      text-transform: uppercase;
      font-weight: bold;

      .leaf {
        list-style: none;
        line-height: .5em;
        text-align: center;
      }
    }

    a {
      color: color("black");
      text-decoration: none;
    }

    li {
      font-size: modular-scale(0);

      ul li {
        display: none;
      }
    }
  }

  .region-nav {

    flex: 1 0;

    @include media($bp-desktop) {
      flex: 1 100%;
      margin-left: 1em;
    }

    .block-menu {

      flex: 1 0;

      @include media($bp-desktop) {
        flex: 1 80%;
      }
    }

    .phone-number {
      flex: 1 20%;
      font-weight: bold;
      color: color("orange");
      text-align: center;
      padding-right: 1em;

      @include media($bp-desktop) {
        flex: 1 18%;
        font-size: 1em;
      }

      @include media($bp-lgdesktop) {
        font-size: 1.111em;
      }

      a {
        @include media($bp-desktop) {
          cursor: default;
        }
      }

      p {
        margin-top: 0;
        font-size: 1.389em;


        @include media($bp-desktop) {
          font-size: 1.111em;
        }
      }
    }
  }

}



// Search bar begin
.search-area {
  display: block;
  flex: 1 100%;
  position: relative;
  text-align: center;
  background: color("orange");
  color: color("white");
  transition: all ease 0.3s;
  width: 90%;
  height: 44px;
  z-index: 101;


  .search_bar_open & {
    top: 0;

    @include media($bp-desktop) {
      top: 0;
      z-index: -55;
    }
  }

  @include media($bp-desktop) {
    width: 100%;
    height:44px;
    left: 0;
  }

}

&.search_bar_open {
  margin-top: 0;


  @include media($bp-desktop) {
  }

  #subnav {
  }
}

.search_toggle_block {
  z-index: 989;
  a {
    display: inline-block;
    color: color("orange");
    padding: 6px;
    height: 44px;
    width: 44px;
    text-align: center;
    font-size: 20px;
    line-height: 30px;
    position: relative;
    top: 0;
    padding-top: 0;


    @include media($bp-tablet) {
      height: 44px;
      width: 44px;
      text-align: center;
      font-size: 20px;
      line-height: 30px;
      position: static;
      top: 0;
    }

    @include media($bp-desktop) {
      width: 30px;
      height: 30px;
      line-height: 20px;
      float: right;
      color: color("orange");
    }

    span {
      margin-top: 10px;

      @include media($bp-desktop) {
        margin-top: 0.4em;
        font-size: 0.8em;
      }
    }

    &.close {
      display: none;
      position:absolute;
      right: 0.8em;
      top: -44px;
      background:color("orange");
      color: color("darkgray");

      @include media($bp-landscape){
        margin-right: 7%;
      }

      @include media($bp-tablet){
        margin-right: 13%;
      }

      @include media($bp-smdesktop){
        margin-right: 14%;
      }

      @include media($bp-desktop) {
        position: relative;
        top: -105px;
      }


      .search_bar_open & {
        display: block;
      }

      @include media($bp-desktop) {
        display: none;
        background:color("orange");
        margin-top: 3px;

        .search_bar_open & {
          display: block;
          position:relative;
          top: -80px; //-49px;
          right: 4em; //-1em;
          z-index: 34;
          font-size: 1.5em;

          @include media($bp-lgdesktop){
            right: 2em;
          }

          @include media($bp-desktop){
            top: -105px;
          }
        }
      }
    }
  }
}
.search_form_real {
  margin-top: 0;
  flex: 1 auto;
  align-content: center;
  text-align: center;

  #search-block-form {

    .form-text {
      width: 55%;
      border: 0;
      height: 44px;
      padding-left: 5px;
    }
  }
}

.form-item {
  margin: 0;
}

#search-block-form .form-submit {
  background: color("orange");
  color: color("white");
  border: none;
  padding: 0 1em;
  height: 22px;
  background-image:url("../../img/search-white.png");
  background-size: contain;
  background-repeat: no-repeat;
  margin-left: 5px;
  text-indent:-9em;

}


// When user logged in
.logged-in {
  #header {
    @include media($bp-desktop) {
      margin-top: 25px;

      .contextual-links-trigger {
        display: none;
      }
    }

    .search-area {

      #block-system-user-menu.block-menu {
        ul {
          li {
            padding: 0px 10px;

            &:nth-child(2){
              display: none;
            }
          }
        }
      }

      .search_toggle_block {
        .contextual-links-trigger {
          display: none;
        }
      }
    }
    #subnav .region-subnav{
      .block-system.block-menu {
        ul {
          li {
            padding: 0px 10px;

            &:nth-child(2){
              display: none;
            }
          }
        }
      }
    }
  }    
}
