.region-bottom-nowrap {
	background-image: url("../../../../../default/files/orange-bkg.jpg");
    background-size: cover;
    background-position: center center;

	.region-blockgroup-contact-group {
		display: flex;
		flex-wrap: wrap;
		align-items: top;
		font-weight: 700;
		text-align: center;
	    color: color("white");
	    padding: 1em 0em;

	    p a {
	    	color: color("white");
	    	text-decoration: underline;

	    	&.button {
	    		text-decoration: none;

	    		&.white {
	    			color: color("orange");

	    			&:hover {
						background: color("white");
				    	color: color("orange");
				    	border: 2px solid color("orange");
					}
	    		}
    		}


	    }

	    .grid-two {
	    	width: 95%;
	    	margin: 0 auto;

	    	h2 {
	    		font-weight: 700;
		    }
	    }

	    @include media ($bp-desktop) {
		    	padding: 1em 3em;

		    	.grid-two {
		    		width: 48%;
		    	}
	    }

	    @include media ($bp-lgdesktop) {
			max-width: 1416px;
			margin: 0 auto;
		}
	}
}

#block-views-find-my-rep-block-1,
#block-multiblock-2 {
  margin: 0 0 0 auto;

  .ajax-progress-throbber {
    display: none !important;
  }

  .view-find-my-rep {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .view-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 44px;
    background-color: color("white");
    color: color("orange");
    cursor: pointer;

    i {
      font-size: 24px;
    }
  }

  .views-exposed-widgets {
    margin: 0;

    .views-exposed-widget {
      padding: 0;
    }

    input {
      margin-top: 0;
    }

    input[type="text"] {
      padding: 0.5em;
      border: 0;

      &:focus {
        outline: none;
      }
    }

    input[type="submit"] {
      display: none;
    }
  }
}
