#block-views-product-details-block {
	text-align: left;

	h4 {
		margin: 1em 0 0;
	}

	.views-field-field-prime-mover-assign {
		margin-bottom: 1em;

		.field-content {
			.node-prime-mover {
				h2 {
					display: none;
				}

				.content {
					display: flex;
					align-items: center;
					padding-left: 0em;

					.field-name-field-prime-mover-icon {
						margin-right: 0.5em;
					}
				}				
			}

			@include media($bp-landscape) {
				display: inline-flex;
				flex-wrap: wrap;
				align-items: start-flex;
				width: 100%;

				.node-prime-mover {
					width: 49%;

					&:nth-child(1), &:nth-child(2) {
						margin-right: 2%;
					}
				}
			}
		}
		
	}

	.views-field-field-markets {

		.field-content {
			display: flex;

			.button {
				margin: 0em 0em 2em 0.15em;

				&first:child {
					margin-left: 0em;
				}
			}
		}
		
	}

	.views-field-field-spec-sheet, .views-field-field-parts-list, .views-field-field-literature {
		width: 100%;
		margin: 1em 0em 2em;

		@include media($bp-landscape) {
			display: inline-flex;
			flex-wrap: wrap;
			align-items: flex-start;
			width: 8.5em;
		}
	}

	@include media ($bp-smdesktop) {
		.views-field-field-featured-image {
			width: 50%;
			float: left;
		}

		.views-field-field-prime-mover-assign, .views-field-field-markets {
			width: 50%;
			float: right;
			//clear: both;
		}

		.views-field-field-spec-sheet {
			margin-left: 50%;
		}
	}
}