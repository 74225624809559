.page-node-3 {
  #main-wrapper {
    margin-top: 5em;

    #main {
      min-width: 320px;
      max-width: 1416px;
      margin: 0 auto;
    }
  }

  h2 {
    font-weight: 700;

    @include media($bp-desktop){
      line-height: 1.333em;
    }
  }

  .region-content {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    top: 0;
    width: 100%;
    float: left;
    
    .catalog-download {
      text-align: left;
    }
  }

  .grid-four {
    padding: 10px;

    @media only screen and (min-width: 580px){
      width: 50%;
    }

    @include media($bp-tablet){
      width: 33.3%;
    }

    @include media($bp-desktop){
      width: 25%;
    }

    a.button{
      min-width: 200px;
    }
    .views-field-field-image{
      margin-bottom: 10px;
    }
  }

  #block-views-manuals-block-1, #node-3{
    margin: auto;
  }
  #block-webform-client-block-91{
    text-align: center;
  }

  .region-sidebar-right {
    width: 100%;
    float: right;
    position: relative;

    #block-webform-client-block-136 {
      background-color: color("black");
      color: color("white");
      margin: 0em;
      padding: 2em 0em 1.5em;
      height: auto;

      .form-item {
        width: 85%;
        margin: 0 auto;
        padding: 0em 0em 1.5em;

        @include media ($bp-desktop){
          width: 30%;
          float: left;
          margin: 0 0.5em;
        }

        input {
          width: 100%;
          &::placeholder {
            padding-left: .5em;
          }
        }
      }

      .form-actions {
        width: 50%;
        text-align: center;
        margin: 0 auto;

        .button-primary {
          margin-top: .8em;
          padding: .5em 2em !important;
        }

        @include media($bp-desktop) {
          text-align: left;
          margin: 0em 0.5em;
        }
      }

      @include media($bp-desktop){
        padding: 2em 1em 1.5em;
      }
    }

    @include media($bp-tablet){
      width: 45%;
    }

    @include media($bp-desktop) {
      width: 60%;
    }
  }

  #block-webform-client-block-91 {
    background-color: white;
    height: auto;

    /*#node-91 {
      min-width: 320px;
      max-width: 1416px;
      margin: 0 auto;
    }*/

    .collapsible {
      border: none;

      .fieldset-legend {
        background-image: none;
      }

      .webform-component {
        width: 100%;

        @include media($bp-tablet) {
          width: 50%;
          float: left;

          &.webform-component--view-form--catalog--other {
            float: none;
          }
        }
      }

      input {
        width: 100%;

        @include media($bp-tablet) {
          &.form-text {
            width: 95%;
          }
        }
      }

      input[type="checkbox"] {
        width: 25px;
      }
    }

    .form-actions {

      input {
        margin-bottom: 1em;
        width: 50%;

        @include media($bp-landscape){
          width: 25%;
        }

        @include media($bp-desktop){
          width: 10%;
        }
      }
    }
  }
}