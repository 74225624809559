.social {
  ul {
    display: flex;
    padding-left: 0px;

    li {
      width: 100%;
      list-style: none;
    }    
  }

  a {
    display: inline-block;
    width: 1.5em;
    font-size: 1.5em;
    text-align: center;
    background-color: color("orange");
    color: color("black");
    border-radius: 50%;

    &:hover {
      background-color: color("white");
      color: color("orange");
    }
  }

  &.grid-two {
    .content {
      @include media($bp-desktop) {
        display: block;
      }
    }
    
    ul {
      @include media($bp-tablet) {
        widtH: 300px;
        margin: 0 auto;
      }
    }

    a {
    background-color: color("white");
    color: color("orange");

      @include media($bp-landscape) {
        font-size: 2em;
      }
    }
  }

  @include media($bp-desktop) {
    .content {
      display: flex;          
      align-items: center;
    }

     ul li a {
      font-size: 1.1em;
      margin: 0px 10px;
    }
  }
}