// timeline
.timeline {
	background: color("black");
	padding: 2em 0em;

	.image-wrap {
		@include media($bp-landscape) {
			display: inline-flex;
			flex-wrap: wrap;
			width: 100%;

			.grid-four {

				@include media($bp-tablet) {
					width: 48%;

					&:nth-child(odd) {
						margin-right: 2%;
					}

					&:nth-child(even) {
						margin-left: 2%;
					}
				}

				@include media($bp-desktop) {
				width: 23%;

					&:nth-child(n-1) {
						margin-right: 2%;
						margin-left: 0%;
					}

					&:last-child {
						margin-right: 0%;
					}
				}
			}
		}
		
		.four-group {
			display: inline-grid;
			padding: 2em 0em;

			@include media($bp-tablet) {
				display: flex;

				img {
					margin-right: 20%;

					@include media ($bp-desktop) {
						margin-right: 5%;
					}
				}
			}

			.fa {
				font-size: 5em;
				color: color("orange");
				margin-top: 0.5em;

				&.fa-angle-double-down {
					@include media($bp-tablet) {
						display: none;
					}
				}

				&.fa-angle-double-right {
					display: none;

					@include media($bp-tablet) {
						display: initial;
					}
				}
			}
		}
	}
}

// Chronicle
.chronicle {
	.left {
		@include media ($bp-smdesktop) {
			float: left;
			width: 38%;
			margin-right: 2%;
		}
	}

	.right {
		@include media ($bp-smdesktop) {
			float: right;
			width: 60%;
			text-align: left;
		}
	}
}

// logos
.about-us {
	border-top: 1px solid color("lightgray");
	clear: both;

	.image-wrap {
		@include media($bp-landscape) {
			display: inline-flex;
			flex-wrap: wrap;
			width: 100%;
		}

		@include media($bp-desktop) {
			max-width: 978px;
			margin: 0 auto;
		}

		.grid-four {

			@include media($bp-landscape) {
				width: 48%;

				&:nth-child(odd) {
					margin-right: 2%;
				}

				&:nth-child(even) {
					margin-left: 2%;
				}
			}

			@include media($bp-tablet) {
				width: 23%;

				&:nth-child(even) {
					margin-left: 2%;
				}

				&:last-child {
					margin-left: 0%;
				}
			}

			img {
				margin: 1em 0em;
			}
		}
	}	
}