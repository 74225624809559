#footer {
	background-color: color("black");
	color: color("gray");
	margin: 0 auto;
	padding: 0px 15px 25px;

	.head-seven {
		font-weight: bold;
	}

	p a {
		color: color("gray");
	}

	.region-footer {
		display: flex;
		flex-wrap: wrap;
		width: 100%;
		padding-top: 1em;

		.block-block {
			border-bottom: 1px solid color("lightgray");

			@include media($bp-landscape) {
				border-bottom: none;
			}

			@include media($bp-tablet) {
				&.grid-three {
				width: 32%;
				margin-right: 2%;
				text-align: center;

					&:nth-child(2) {
						margin-left: 0em;
					}

					&:nth-child(3) {
						margin-right: 0%;
					}

					ul {
						text-align: center;
					}				
				}
			}

			&:last-child {
				border-bottom: none;
			}

			@include media($bp-desktop){
				&.grid-three {
					&#block-block-13 {
						text-align: left;
					}

					&.social {
						text-align: left;
						margin: 0 auto;

						.content {
							align-items: baseline;
						}

						.head-seven, ul {
							width: 50%;
						}

						ul {
							margin-top: 0px;
						}

					}

					&#block-block-26 {
						text-align: right;
					}
				}
			}			
		}

		@include media($bp-lgdesktop) {
			max-width: 1416px;
			margin: 0 auto;
		}
	}
}

#sub-footer {
	background-color: color("black");
	color: color("gray");
	border-top: 1px solid color("gray");
}

.region-subfooter {	
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	width: 100%;
	min-width: 320px;
	padding: 10px 0px;

	a {
		text-decoration: underline;
		color: color("white");
		font-weight: bold;
	}

	img {
		width: 150px;
	}

	.grid-two {
		text-align: center;
		margin: 0 auto;
		padding: 0px 15px;

		@include media($bp-tablet) {
			width: 48%;
			text-align: left;
			margin: 0em;

			&:first-child {
				margin-right: 4%;
			}

			&:last-child {
				text-align: right;
			}
		}
	}

	@include media($bp-lgdesktop) {
		max-width: 1416px;
		margin: 0 auto;
	}
}