.markets-front {
	background-color: color("darkgray");
	color: color("white");

	@include media($bp-smdesktop) {
		background: url("../../img/markets-we-serve-bkg.jpg") bottom no-repeat;
		background-size: cover;
		color: color("white");
		text-align: center;
		margin: 0 -1em !important;
		height: 620px;
	}

	@include media($bp-landscape) {
		margin: 0 45px;
	}

	h2 {
		font-size: 2.5em;
		font-weight: bold;
		line-height: 1.25em;
		margin: 0;

		@include media($bp-smdesktop) {
			font-size: 3em;
			line-height: 3.333em;
		}
	}

	h4 {
		font-size: 1.2em;

		@include media($bp-smdesktop) {
			font-size: 1.556em;
			line-height: 1.5em;
		}

		@include media($bp-lgdesktop) {
			font-size: 1.2em;
		}
	}

	.services-group {
		@include media($bp-smdesktop) {
			display: flex;
			//flex-wrap: wrap;
			margin-top: -2em;
		}

		@include media($bp-desktop) {
			margin-top: -1em;
		}

		@include media($bp-lgdesktop) {
			margin-top: -2em;
		}
	}

	.service {
		//overflow: hidden;
		border: 1px solid color("gray");

		@include media($bp-tablet) {
			overflow: visible;
			text-align: center;
			margin: 1.2%;
			width: 100%;
			height: 240px;
			border: none;
		}

		@include media($bp-smdesktop) {
			overflow: visible;
			text-align: center;
			margin: 1% 1% 0 1%;
			height: 230px;
			width: 100%;
			padding: 20px;
			font-size: 0.8em;
			background-color: transparent;
		}

		@include media($bp-lgdesktop) {
			font-size: 1em;
			margin: 1% .1% 0;
		}

		i {
			color: color("orange");
			margin: 0 5px;

			#hover {
				display: none;
			}
		}

		p {
			padding-right: 30px;

			@include media($bp-smdesktop) {
				padding: 0;
				font-size: 0.8em;
			}

			@include media($bp-lgdesktop) {
				font-size: 1em;
				margin-top: 0.5em;
			}
		}

		p, button {
			color: color("orange");
			background-color: transparent;
			border: none;
		}

		img {
			margin: 0 auto;
		}

		img.img-tablet {
			display: none;

			@include media($bp-tablet) {
				display: block;
				float: left;
				margin: 1em 0 0 2em;
			}

			@include media($bp-smdesktop) {
				float: none;
				margin: auto;
				height: 100px;
				//width: 229px;
			}

			@include media($bp-lgdesktop) {
				//height: auto;
				height: 153px;
				width: auto;
			}
		}

		img.img-hover {
			display: none;

			@include media($bp-smdesktop) {
				height: 100px;
				float: none;
				margin: auto;
				//width: 229px;
			}

			@include media($bp-lgdesktop) {
				height: 153px;
				width: auto;
			}
		}

		@include media($bp-landscape) {
			button {
				width: 30%;
			}
		}

		@include media($bp-smdesktop) {
			height: 100px;
			//width: 229px;
		}

		@include media($bp-desktop) {
			width: 30%;
			height: 240px;

			button {
				width: 50%;
			}
		}
	}

	.service:last-child {
		//border-bottom: 1px solid color("lightgray");
		margin-bottom: 20px;

		@include media($bp-smdesktop) {
			border-bottom: none;
			margin-bottom: 0;
		}
	}

	input {
		position: absolute;
		opacity: 0;
		z-index: -1;

		@include media($bp-tablet) {
			display: none;
		}
	}

	// controlling the tabs
	label, h4 {
		position: relative;
		display: block;
		padding: 0 0 0 0.3em;
		font-weight: bold;
		line-height: 2em;
		margin: 0;
		cursor: pointer;
		color: color("white");
		text-transform: capitalize;

		@include media($bp-tablet) {
			color: color("white");
		}
	}

	h4 {
		@include media($bp-tablet) {
			width: 60%;
			float: right;
		}

		@include media($bp-smdesktop) {
			width: 100%;
			float: none;
			line-height: 1.5em;
		}

		//@include media($bp-lgdesktop) {
		//  line-height: 2em;
		//}
	}

	i.fa {
		display: none;

		@include media($bp-smdesktop) {
			display: inline-block;

			&#hover {
				display: none;
			}
		}
	}

	.tab-content {
		max-height: 0;
		overflow: hidden;
		transition: max-height .35s;
		background-color: color("white");
		padding: 0 5px;

		@include media($bp-tablet) {
			overflow: visible;
			max-height: 100%;
			background: transparent;
			width: 60%;
			float: right;
		}

		@include media($bp-smdesktop) {
			width: 100%;
			float: none;
			padding: 0;
		}

	}

	.tab-content p {
		//margin: 0 1em;
		color: color("darkgray");

		@include media($bp-tablet) {
			color: color("white");
		}

		@include media($bp-smdesktop) {
			display: none;
		}
	}

	.tab-content button {
		margin: 1em;
		padding: .5em;
		background: color("orange");
		color: color("white");

		@include media($bp-tablet) {
			margin-bottom: 2em;
		}

		@include media($bp-smdesktop) {
			display: none;
		}
	}

	input:checked ~ .tab-content {
		max-height: 20em;

		@include media($bp-tablet) {
			max-height: 100%;
		}
	}

	label::after, h4::after {
		position: absolute;
		right: 0;
		top: 0.5em;
		display: block;
		width: 3em;
		height: 2em;
		text-align: center;
		transition: all .35s;
		-webkit-transition: all .35s;
		-o-transition: all .35s;

		@include media($bp-tablet) {
			display: none;
		}
	}

	input[type=radio]:checked + label,
	input[type=radio]:checked + label h4 {
		background: color("white");
		color: color("orange");

		@include media($bp-tablet) {
			background: transparent;
			color: color("white");
		}
	}

	input[type=radio] + label::after,
	input[type=radio] + h4::after {
		content: "\f107";
		font-family: FontAwesome;

		@include media($bp-tablet) {
			display: none;
		}
	}

	input[type=radio]:checked + label::after,
	input[type=radio]:checked + h4::after {
		transform: rotateX(180deg);

		@include media($bp-tablet) {
			display: none;
		}
	}
}
// For desktop
.service:hover {
	@include media($bp-smdesktop) {
		height: 426px;
		background-color: color("orange");
		color: color("white");

		h4 {
			color: color("white");
			padding-bottom: 10px;
			line-height: 1em;
		}

		i {
			display: none;

			&#hover {
				display: inline-block;
				color: color("white");
			}
		}

		img.img-tablet {
			display: none;

			@include media($bp-smdesktop) {
				height: 100px;
				//width: 229px;
			}

			@include media($bp-lgdesktop) {
				height: 153px;
				width: auto;
			}
		}

		img.img-hover {
			display: inherit;

			@include media($bp-smdesktop) {
				height: 100px;
				//width: 229px;
			}

			@include media($bp-lgdesktop) {
				//height: auto;
				width: auto;
			}
		}

		.tab-content p {
			background: color("orange");
			color: color("white");
			display: inherit;
			height: 100%;
		}

		.tab-content button {
			display: inherit;
			background: color("orange");
			color: color("white");
			border: 1px solid color("white");
			text-align: center;
			margin: 20px auto;
			padding: 10px;
		}
	}

	@include media($bp-lgdesktop) {
		height: 460px;

		h4 {
			line-height: 1.2em;
		}
	}
}