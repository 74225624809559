.block-gtranslate {
  font-size: 14px;
  font-style: italic;
  padding-right: 1.5em;

  select {
    border: 1px solid color("orange");

    &:focus {
      outline: 1px solid color("orange");
    }
  }
}