// Home Page Slider Banner Start
#block-views-homepage-slider-block-2, #block-views-homepage-slider-block-3 {
  margin-top: -10px;

  .slides {
    max-width: 1850px;
    margin: 0 auto;
  }

  .views-field-field-slide-image {
    @include media($bp-tablet) {
      width: 60%;
      float: right;
      position: relative;

      img {
        float: right;
        height: 350px;
        object-fit: cover;
        // object fit images polyfill
        font-family: "object-fit: cover;";
      }
    }

    @include media($bp-desktop) {
      img {
        height: 400px;
      }
    }

    @include media($bp-lgdesktop) {
      min-width: 50%;
      max-width: 1100px;
      float: right;
      position: relative;

      img {
        height: 500px;
      }
    }
  }

  .views-field-nothing {
    display: flex;
    //justify-content: center;
    align-items: center;
    background-color: color("black");
    color: color("white");

    @include media($bp-tablet) {
      width: 100%;
      height: 100%;
      position: absolute;
      background: url('../../img/home-text-background.png') no-repeat;
      background-size: 55% 100%;
    }

    .field-content {
      display: flex; //block;
      align-items: center;
      width: 100%;
      max-width: 1416px;
      margin: 0 auto;
      height: 100%;
    }

    .slide-content {
      width: 100%;
      padding: 1em;

      @include media($bp-tablet) {
        width: 40%;
        padding-bottom: 1em;
        //padding: 1.5em 0 0 1em;
      }

      @include media($bp-smdesktop) {
        //padding-top: 2.5em;
      }

      @include media($bp-desktop) {
        //padding-top: 4em;
        padding-left: 35px;
      }

      @include media($bp-desktop){
        max-width:600px;
      }
    }

    h1 {
      @include media($bp-tablet) {
        font-size: 1.25em;
        line-height: 1.2em;
      }

      @include media($bp-lgdesktop) {
        font-size: 2.222em;
      }
    }

    p {

      margin-bottom: 1.5em;
      
      @include media($bp-tablet) {
        line-height: 1em;
        //margin-bottom: 0em;
      }

      @include media($bp-smdesktop) {
        font-size: 1em;
        line-height: 1.3em;
      }

      @include media($bp-lgdesktop) {
        font-size: 1.35em;
        line-height: 1.25em;
      }

    }
  }

  .flex-control-nav {
    z-index: 50;

    li a {
      background: color("white");

      &.flex-active {
        background: color("orange");
      }
    }
  }

  .flex-direction-nav {
    display: none;
  }
}

.logged-in #block-views-homepage-slider-block-2,.logged-in #block-views-homepage-slider-block-3 {
    margin-top: -39px;

    @include media($bp-desktop) {
      margin-top: -14px;
    }
}

// Home Page Slider Banner End

// Interior Banner Start

.interior {
  h1 {
    margin: 0 10px 15px;
    color: color("orange");
    font-family: $button-font;
    font-size: 3em;
    font-weight: 900;
    line-height: 1em;
    text-transform: uppercase;
  }

  p {
    margin: 0 10px;
    font-size: 1.111em;
    line-height: 1.5em;
    font-weight: 900;
  }

  img {
    width: 100%;
    height: 150px;
    object-fit: cover;
    // object fit polyfill
    font-family: "object-fit: cover;";
  }

  @include media($bp-tablet) {
    height: 200px;
    position: relative;

    .views-field-nothing {
      width: 90%;
      position: relative;
      margin: 0 5%;
      padding: 2em 0 0;
    }

    img {
      @include size(100%);
      @include position(absolute, 0 null null 0);
      z-index: -1;
      object-fit: cover;
      // object fit images polyfill
      font-family: "object-fit: cover;";
    }

    h1 {
      margin-bottom: 0;
    }

    p {
      color: color("white");
      font-size: 1em;
    }
  }

  @include media($bp-smdesktop) {
    height: 250px;

    h1 {
      font-size: 3.5em;
    }

    p {
      font-size: 1.2em;
    }
  }

  @include media($bp-desktop) {
    height: 300px;

    .views-field-nothing {
      padding: 3em 0 0;
    }

    h1 {
      font-size: 4em;
    }

    p {
      font-size: 1.35em;
      line-height: 1.5em;
    }
  }

  @include media($bp-lgdesktop) {
    width: 1680px;
    height: 410px;
    margin: 0 auto;


    .views-field-nothing {
      width: 85%;
      max-width: 1416px;
      margin: 0 auto;
    }

    h1 {
      font-size: 4.167em;
    }

    p {
      margin-top: 0;
      font-size: 1.667em;
      line-height: 1.52em;
    }
  }

}
// Interior Banner End

// Interior Markets Subnav Banner Start

.node-type-markets {
	.interior {
    height: 300px;
    position: relative;

    @include media($bp-tablet) {
      height: 700px;
    }

    .views-field-nothing {
      @include position(absolute, null null 5% 0);

      @include media($bp-tablet) {
        position: static;
        padding: 570px 0 0;
      }
    }

		h1, h2, h3 {
			color: color("white");
      text-shadow: 3px 3px 7px rgba(0, 0, 0, 0.65);
		}

    img {
      @include size(100%);
      @include position(absolute, 0 null null 0);
      object-fit: cover;
      // object fit polyfill
      font-family: 'object-fit: cover;';
    }
	}
}

// Interior Markets Subnav Banner End

// Interior Banners in Blocks - display on view-generated pages
.no-body {
  text-align: left;
  background-color: color("lightgray");
  border-bottom: 1px solid color("gray");

  @include media($bp-tablet) {
  height: 7em;
  position: relative;

    .content {
      width: 90%;
      position: relative;
      margin: 0 5%;
      padding: 2em 0 0;
    }
  }

@include media($bp-smdesktop) {
  margin-bottom: 2em;
}

@include media($bp-desktop) {
  height: 11em;

  .content {
    padding: 3em 0 0;
  }
}

@include media($bp-lgdesktop) {

  .content {
      width: 85%;
      max-width: 1416px;
      margin: 0 auto;
    }
  }
}


