.grid-two {
	width: 100%;

	@include media($bp-desktop) {
		width: 48%;

		&:first-child {
			margin-right: 4%;
		}
	}
}

.grid-three {
	width: 100%;

	@include media($bp-landscape) {
		width: 48%;

		&:first-child {
			margin-right: 4%;
		}
	}

	@include media($bp-desktop) {
		width: 32%;

		&:nth-child(2) {
			margin: 0em 2%;
		}

		&:first-child, &:last-child {
			margin-right: 0%;
		}
	}
}

.grid-four {
	width: 100%;

	@include media($bp-tablet) {
		width: 48%;

		&:last-child {
			margin-left: 4%;
		}
	}

	@include media($bp-desktop) {
		width: 23%;
		margin-right: 2%;

		&:last-child {
			margin: 0%;
		}
	}
}

.grid-five {
	width: 100%;
	margin: 0 auto;

	@include media($bp-landscape) {
		width: 48%;

		&:last-child {
			margin-left: 4%;
		}
	}

	@include media($bp-smdesktop) {
		width: 32%;
		margin: 0em;

		&:nth-child(2) {
			margin: 0em 2%;
		}

		&:first-child, &:last-child {
			margin-right: 0%;
		}
	}

	@include media($bp-desktop) {
		width: 23%;
		margin-right: 2%;
		text-align: left;

		&:last-child {
			margin-right: 0%;
		}
	}

	@include media($bp-lgdesktop) {
		width: 18%;

		&.views-row {
			margin: 0em 2% 0em 0em;
		}

		&.views-row-6 {
			margin: 0%;
		}
	}
}

.flex-grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 2em;

  > div {
    margin: 0.5em 2em;
  }
}
