a {

  &.button {
    font-family: $button-font;
    font-size: 1em;
    font-weight: bold;
    line-height: 1.222em;
    text-align: center;
    text-transform: uppercase;
    padding: 10px 25px;

    &:after {
      font-family: FontAwesome;
      content: "\f054";
      margin-left: 5px;
    }
  }

  &.orange {
    border: 2px solid transparent;
    background: color("orange");
    color: color("white");
    text-transform: uppercase;

    &:hover {
      background: color("white");
      color: color("orange");
      border-color: color("orange");
    }
  }

  &.white {
    background: color("white");
    color: color("orange");
    border: 2px solid color("orange");

    &:hover {
      background: color("orange");
      color: color("white");
    }
  }

  &.file {
    display: block;
    min-width: 300px;

    i {
      margin-right: 0.3em;
    }

    &:after {
      display: none !important;
    }
  }
}

p.button {
    text-transform: uppercase;
    color: color("black");
}

.webform-submit {
  font-family: $button-font;
  text-transform: uppercase;
  background-color: color("orange");
  color: color("white");
  border: none;
  padding: 5px 20px;
}
