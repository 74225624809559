// For the Find My Rep styles - temp identifiers, will change once the views and blocks are created
#edit-field-zip-search-target-id-wrapper {
  padding-right: 0px;
}

#block-views-find-my-rep-block-2,
#block-views-0cd192d2e23ca2391db004bf98d509ee {
  margin: 0px !important;

  .views-exposed-widget.views-submit-button {
    display: none;
  }
}

#views-exposed-form-find-my-rep-block-2,
#views-exposed-form-find-my-rep-take-2-zip-ref-block-2 {
  .views-exposed-widgets {
    display: flex;
    justify-content: center;
  }
}

// presentation of the pop-up box
.overlay {
  position: fixed;
  height: 100%;
  width: 100%;
  background: color("darkgray");
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .close-overlay {
    position: absolute;
    top: 5px;
    right: 5px;
  }

  .close-overlay:hover {
    cursor: pointer;
  }

  .close-overlay::before {
    position: absolute;
    display: block;
    content: '';
    height: 4px;
    width: 20px;
    background: color("gray");
    transform: rotate(45deg);
    top: 10px;
    right: 2px;
  }

  .close-overlay::after {
    position: absolute;
    display: block;
    content: '';
    height: 4px;
    width: 20px;
    background: color("gray");
    transform: rotate(-45deg);
    top: 10px;
    right: 2px;
  }
}

.find-my-rep-active {
  .overlay {
    display: flex;
  }

  #wrapper {
    filter: blur(2px);
    transition: all 0.5s;
  }
}

.overlay-wrapper {
  background: color("darkgray");
  padding: 40px 50px;
  position: relative;
}

body.find-my-rep-active {
  max-height: 100%;
  max-width: 100%;
  overflow: hidden;
}

#block-views-find-my-rep-block {
  width: auto;
  position: fixed;
  top: -100%;
  right: -100%;
  background: color("darkgray");
  color: color("white");
  z-index: -1;
  transition: transform 300ms;
  transform: translateY(-200%);

  &.is-active {
    transform: translateY(0);
  }

  &.no-transition {
    transition: none;
  }

  .ajax-progress-throbber {
    display: none !important;
  }

  > .content{
    padding: 24px 32px;
    border-top: 5px solid color("orange");
  }

  form {

    .views-exposed-widgets{
      display: flex;
    }

    label{
      display: none;
    }

    input{
      font-family: $body-font;
    }

    #edit-body-value-wrapper{
      width: 100%;
    }

    #edit-body-value-wrapper, .views-submit-button{
      padding: 0;
    }

    input[type="text"]{
      padding: 8px 12px;
      margin: 0;
      border: none;
      box-shadow: none;
      outline: none;
      width: 100%;
    }

    input[type="submit"]{
      margin: 0;
      border: none;
      box-shadow: none;
      outline: none;
      background: color("orange");
      color: color("white");
      padding: 8px 12px;
      font-size: 1em;
    }

  }

  p{
    margin-bottom: 0;
  }
}

#block-views-find-my-rep-block,
#block-views-find-my-rep-block-1,
#block-multiblock-2 {
  .view-content {
    display: none !important;
  }
}

#find-my-rep-results {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;

  >.views-row{
    color: color("darkgray");
    border: 3px solid color("orange");
    padding: 38px 48px;
    background: color("white");
    min-width: 50%;
    position: relative;
  }

  #find-my-rep-close {
    position: absolute;
    top: 12px;
    right: 15px;
    font-size: 1.2em;
  }

  .views-field-field-email, .views-field-field-email-1{
    margin-top: 18px;
    margin-bottom: 18px;
  }
  .views-field-field-inside-sales{
    margin-top: 32px;
    padding-top: 18px;
    border-top: 1px solid color("orange");
  }
  .views-field-field-inside-sales, .views-field-field-outside-sales{
    font-size: 2em;
    font-weight: bold;

    span{
      font-size: 12px;
      color: color("orange");
    }
  }
  .views-field-field-position-1{


    span{
      display: none;
    }
  }

  .views-field-field-phone, .views-field-field-phone-1{
    padding: 12px 0;

    .field-content{
      font-weight: bold;
    }
    i{
      padding: 12px 13px;
      background: color("orange");
      border-radius: 50%;
      color: color("white");
      margin-right: 10px;
    }
  }

  .views-field-field-position, .views-field-field-position-1{
    margin-top: 5px;
  }
}
