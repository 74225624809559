.home-statement {

  h5 {
    text-transform: none;

    @include media($bp-smdesktop) {
      width: 60%;
      margin: 0 auto;
    }
  }

  p {
    margin-top: 2em;
  }
}