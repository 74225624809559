/**
 * THEME COLORS
 */
$theme-admin-menu-primary-bg: #101010;
$theme-admin-menu-secondary-bg: #45454A;
$theme-admin-menu-font-color: #fafafa;

/**
 * COLOR MAP
 */
$colors: (
  "white": #fff,
  "black": #31302f,
  "orange": #f26522,
  "darkgray": #5d5c5b,
  "gray": #b6b6b6,
  "lightgray": #eaeaea
);

/**
 * FONT IMPORTS AND SETTINGS
 */

$admin-font: $verdana;
$body-font: "Lato";
$header-font: "Lato";
$button-font: "Open Sans Condensed";

/**
 * GLOBAL SETTINGS
 */
$bp-landscape: new-breakpoint(min-width 450px);
$bp-tablet: new-breakpoint(min-width 768px);
$bp-smdesktop: new-breakpoint(min-width 880px);
$bp-desktop: new-breakpoint(min-width 1024px);
$bp-lgdesktop: new-breakpoint(min-width 1300px);
