.mm-offcanvas {
  z-index: 10000;
}

#header #block-system-main-menu.block-menu, .block-block.phone-number {
  display: none;
  visibility: hidden;

  @include media($bp-desktop) {
    display: block;
    visibility: visible;
  }
}

.block-responsive-menu {
  @include media($bp-desktop) {
    display: none;
    visibility: hidden;
  }
}

.block-menu {
  @include media($bp-desktop) {
    ul {
      li {
        position: relative;
        margin: 0;
        padding: 2em 0;
        line-height: .5em;
        list-style: none;

        a {
          font-size: 12px;
        }

        &:hover ul {
          display: block !important;
          position: absolute;
          margin-top: 1% !important;
          background-color: color("darkgray");
          border-top: 2px solid color("orange");

          .leaf {
            text-align: left !important;
            width: 100%;
          }

          li {
            display: block !important;
            min-width: 140px;
            margin: 0;
            padding: 0;

            a {
              display: block;
              padding: 0.4em 0.4em 0.4em 0.8em;
              color: color("white") !important;
              font-weight: bold;
              font-family: $button-font;
              font-size: 12em;
            }
          }
        }
      }
    }

  }

  @include media($bp-desktop) {
    ul {
      li {
        &:hover ul {
          display: block !important;
          position: absolute;
          margin-top: 1.9em !important;
          background-color: color("darkgray");
          border-top: 2px solid color("orange");

          .leaf {
            text-align: left !important;
            width: 100%;
          }

          li {
            display: block !important;
            min-width: 160px;

            a {
              color: color("white") !important;
              font-weight: bold;
              font-family: $button-font;
              font-size: 12px;
            }
          }
        }
      }
    }
  }

  @include media($bp-lgdesktop) {
    ul {
      li {
        a {
          font-size: 15px;
        }

        &:hover ul {
          
          li {
             
             a {
              font-size: 15px;
             }
          }
        }
      }
    }
  }
}

#block-responsive-menu-toggle {
  background: color("orange");
  margin-right: 1.5em;

  span.label {
    display: none;
    visibility: hidden;
  }

  @include media($bp-tablet) {
    margin-right: 0;
  }

  @include media(bp-smdesktop) {
    display: none;
    visibility: hidden;
  }
}


