#breadcrumb {
  background-color: color("darkgray");
  color: color("lightgray");
  font-size: 0.889em;
  margin: 0 auto;

  .breadcrumb {
  	min-width: 320px;
  	max-width: 1416px;
    margin-top: 1.3em;
    padding: 0.25em 0em 0.25em 15px;

    @include media($bp-desktop) {
    	margin: 0 auto;
    	padding-top: 1.55em;
    }
  }  
}

.not-front {
    &.logged-in {
      #breadcrumb {
        margin-top: -1.8em;

        @include media($bp-desktop){
          margin-top: 0em;
        }
      }
    }
}