.sidebar-left {
  .region-content {
    display: block;

    @include media($bp-tablet) {
      width: 65%;
      float: right;
    }

    @include media($bp-smdesktop) {
      width: 75%;
    }
  }
}
.region-sidebar-left {
  display: block;

  @include media($bp-tablet) {
    width: 35%;
    float: left;
  }

  @include media($bp-smdesktop) {
    width: 25%;
  }
}
