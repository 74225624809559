.region-blockgroup-home-lit-news-events {
	display: flex;
	width: 100%;
	flex-wrap: wrap;
	align-items: top;
	text-align: left;

	.grid-two {

		p:first-child {
			margin-bottom: 2em;
		}		

		@include media ($bp-tablet) {
			.content {
				display: flex;
				align-items: center;

				.float-left, .float-right {
					width: 48%;
				}

				.float-right {
					margin-left: 4%;
				}
			}
		}

		@include media($bp-smdesktop) {
			h4 {
				margin: 0px;
			}
		}

		@include media($bp-desktop) {
			&:first-child {
				margin-right: 2%;
			}
		}

	}
}

	// News & Social
#main-wrapper .region-content {
	.block-quicktabs {

		.content {
			min-width: 100%;
			max-width: 100%;
			width: 23%;
			margin: 0em;
			padding: 0em;
		}

		.quicktabs-tabs {
			margin: 0px;

			li {
				margin: 0px;
				padding: 0px;

				&.first {
					margin-right: 2em;
				}

				&.active {
					border-bottom: 3px solid color("orange");
				}	
			}					
		}

		.quicktabs_main {
			border-top: 1px solid color("gray");

			#block-views-news-page-block-2 {
				margin-top: 1em;
			}

			.view-id-news_page {
				padding: 0em;

				@include media($bp-desktop) {
					width: 23%;
					min-width: 100%;
					max-width: 100%;
				}
			}

			#block-block-28 {
				margin: 0px;
			}
		}

		
	}

	.views-field-title-field a {
		color: color("black");
	}

	#block-views-news-page-block-1 {
		.content, .view-display-id-block_1 {
			min-width: 100%;
			max-width: 100%;
			width: 23%;
			margin: 0em;
			padding: 0em;
		}

		h4 {
			margin: 0em 0em 0.833em;

			@include media($bp-tablet) {
				margin: 0px;
			}

		}

		.view-content {
			border-top: 1px solid color("gray");

			@include media($bp-smdesktop) {
				border-top: none;
			}
		}
	}
}