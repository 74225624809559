/* News Feed */
.view-news-page.view-display-id-page {
	a {
		color: color("black");

		:hover {
			text-decoration: underline;
		}
	}

	.views-row {
		border-bottom: 1px solid color("darkgray");
		padding: 2em 0em;

		@include media($bp-landscape) {
			display: flex;
			align-items: center;
			text-align: left;
		}

		.views-field-nothing {
			@include media($bp-landscape) {
				width: 53%;
				margin-left: 2%;
			}

			@include media($bp-smdesktop) {
				width: 73%;
			}
		}

		.views-field-field-featured-image {
			img {
				border: 1px solid color("gray");
			}

			@include media($bp-landscape) {
				width: 45%;
			}

			@include media($bp-smdesktop) {
				width: 25%;
			}
		}
	}

	.views-row-last {
		border-bottom: none;
	}
}

/* News Articles */
.node-type-news-article #main-wrapper {
	

	.region-content {
		text-align: left;
	}

	h1, h1 > p {
		text-transform: none;
		margin: 0em;
	}

	.block-addtoany {
		padding: 0em 15px;

		a {
			color: color("white");

			&:hover {
				color: color("orange");
			}
		}
	}

	@include media($bp-tablet){
		#main {
			min-width: 320px;
			max-width: 1416px;
			margin: 0 auto;
			padding: 2em 0em;
		}
	}
}


/* Events*/
.page-news-events  #main-wrapper .region-content {
	padding: 0em;

	.view-display-id-page_1 {
		padding: 0em 15px;

		.view-content {
			@include media($bp-desktop) {
				max-width: 65%;
				margin: 0 auto;
			}
		}
	}
}

.view-display-id-page_1 {

	.view-grouping-header {
		text-align: right;
		width: 37%;
		padding-right: 5px;

		@include media($bp-tablet){
			width: 17%;
		}
	}

	.view-grouping {
		border-bottom: 1px solid color("lightgray");
		padding: 1em 0em;

		&:last-child {
			border-bottom: none;
		}
	}

	.view-grouping-content {
		display: inline-flex;
		flex-wrap: wrap;
		width: 100%;
		
		h3 {
			width: 37%;
			height: 47px;
			vertical-align: middle;
			text-align: right;
			border-right: 3px solid color("orange");
			padding-right: 5px;

			&:first-child {
				margin-top: 0em;
			}

			@include media($bp-tablet) {
				width: 17%;
			}
		}

		.views-view-grid {
			width: 63%;
			text-align: left;
			border-bottom: 1px solid color("lightgray");
			margin-bottom: 1em;

			&:last-child {
				border-bottom: none;
				margin-bottom: 0em;
			}

			h4 {
				margin: 0em;
			}

			.col-1 {
				@include media($bp-tablet) {
					display: flex;
					align-items: center;
					padding-bottom: 1em;
				}
			}

			.views-field-field-icon {
				margin-left: 10px;

				@include media($bp-tablet) {
					width: 16%;
					margin: 0em 15px;
				}

				img {
					max-width: 100px;
					max-height: 60px;
				}
			}

			.views-field-nothing {
				margin-left: 10px;

				@include media($bp-tablet) {
					width: 59%;

					.info {
						display: flex;

						.head-seven {
							margin: 0em;

							&:first-child {
								margin-right: 2em;
							}
						}
					}
				}
			}

			.views-field-field-event-url {
				margin: 1em 0em 2em 10px;

				@include media($bp-tablet) {
					width: 26%;
				}

				@include media($bp-desktop){
					width: 29%;
					margin: 0em;
				}
			}

			@include media($bp-tablet) {
				width: 83%;
			}	
		}

		@include media($bp-desktop) {
			align-items: center;
		}
	}
}