#block-views-product-details-block-1 {
	.content  {
		h2, .links {
			display: none;
			visibility: hidden;
		}

		h3 {
			border-bottom: 1px solid color("gray");
		}

		a {
			color: color("black");
		}

		.view-content {
			display: flex;
			flex-wrap: wrap;
			align-items: flex-end;
			width: 100%;


			.grid-three {
				@include media($bp-landscape) {
					width: 48%;
					margin: 0 auto;

					&:first-child {
						padding-right: 4%;
					}
				}

				@include media($bp-desktop) {
					width: 32%;

					&:nth-child(2) {
						padding: 0em 2%;
					}

					&:first-child, &:last-child {
						padding-right: 0%;
					}
				}

				h3 {
					border-bottom: none;
				}				
			}
		}
	}
}