.contact {
  z-index: 0;
  position: relative;

  li {
    margin-bottom: 0em;
  }
}

.address_list {
  display: none;
}

.contact-region {
  position: relative;
  display: flex;
  flex-wrap: wrap;

  #map {
    height: 500px;
    background-color: color("lightgray");
    order: 1;
    flex: 1 100%;

    h1 {
      margin: 0;
    }
  }

  .container {
    width: 25%;
    height: 50%;
    z-index: 10;
    margin: 3% 0.278em 0.278em 0.278em;
    cursor: pointer;
    text-align: left;
    padding: 0.278em;
    background-color: color("white");
    position: relative;
  }

  .locations-region {
    background-color: color("white");
    padding: .4em 1.5em;
    width: 95%;
    flex: 1 100%;
    z-index: 6;

    @include media($bp-tablet) {
      width: 95%;
    }

    @include media($bp-smdesktop) {
      width: 55%;
      margin-top: .5%;
      position: absolute;
      top: 1em;
      left: 3.5em;
    }

    @include media($bp-desktop) {
      width: 452px;
    }

    .dropdown-wrapper {
      .dropdown {
        border: 0.056em solid color("darkgray");
        color: color("orange");
        padding: 0.278em;
      }

      .address {
        padding: .567em 1.2em;
      }

      .fa-phone {
        background-color: color("orange");
        color: color("white");
        border-radius: 50%;
        width: 30px;
        height: 30px;
        padding: 6px 8px;
      }

      .contact-button {
        cursor: pointer;
        color: color("white");
        width: 80%;
        height: 48px;
        text-align: center;
        padding: 8px 0 0 0;
        background-color: color("orange");
        border: 2px solid color("orange");
        border-radius: 24px;
        font-family: $button-font;
        text-transform: uppercase;

        @include media($bp-tablet) {
          width: 50%;
        }

        @include media($bp-smdesktop) {
          width: 40%;
        }

        @include media($bp-desktop) {
          width: 30%;
        }
      }

      a.toggle {
        text-decoration: none;
        text-align: left;
        font-weight: 900;
        padding: 0 1em;
        line-height: 2em;

        span {
          float: right;
          margin-top: 0.5em;
          margin-right: 1em;
          color: color("orange");
          transition: all ease .3s;
        }

        &.open span {
          transform: rotate(180deg);
        }
      }

      .dropdown-content {
        border: 0.056em solid color("lightgray");
        position: absolute;
        background-color: color("white");
        margin-top: 0.278em;
        margin-left: -0.3em;
        width: 90%;

        ul {
          margin: 0;
          padding: 0;
          list-style: none;

          li {
            a {
              display: block;
              color: color("darkgray");
              padding: 0.5em;
              border-bottom: 0.056em solid color("lightgray");
              text-decoration: none;
            }
          }
        }
      }
    }

    h2 {
      position: static;
      margin: 0;
      text-transform: capitalize;
      font-weight: 900;
      margin-bottom: 0.5em;
      line-height: 1.5em;
      color: color("darkgray");
    }
  }
}