.page-search-node {
  .search-form {
    min-width: 320px;
    max-width: 1416px;
    margin: 0 auto;
    padding: 0px 15px;

    #edit-keys {
      width: 85%;
      margin: 0.5em 0em 1em;
    }
  }

  .search-results {
    min-width: 320px;
    max-width: 1416px;
    margin: 0 auto;
    padding: 0px 15px;

    .search-result {
      border-bottom: 1px solid color("lightgray");
      padding: 0em 15px;

      &:last-child {
        border-bottom: none;
      }

      .search-info {
        display: none;
      }
    }
  }
}