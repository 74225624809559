.view-teams-sales {
	.view-content {
		display: inline-flex;
		flex-wrap: wrap;
		align-items: flex-start;

		h1 {
			text-transform: capitalize;
			width: 100%;
			color: color("gray");
			margin-bottom: 0em;

			@include media($bp-desktop) {
				text-align: left;
			}
		}

		h4 {
			margin-bottom: 0em;

			@include media($bp-smdesktop) {
				margin-top: 0em;
			}
		}

		.head-seven {
			margin-top: 0em;
		}

		.views-field-field-email {
			margin: 1em 0em 2.5em;
		}
	}	
}