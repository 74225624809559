.page-node-download {
  #page {
    .region-content,
    .region-bottom-nowrap {
      margin: 0 auto;
      text-align: left;
    }

    .region-content {
      width: 90%;
      padding-top: 1em;

      h2 {
        font-weight: 700;
      }
    }
  }
}
