/* Foursquare position and hover actions (LANDING PAGES) */
.square-link .view-content {
  display: flex;
  align-items: space-between;
  justify-content: center;
  flex-flow: row wrap;
  align-content: flex-end;
  z-index: -10;
  margin: 3em 0;
}

.square-link .views-row {
  position: relative;
  text-align: center;
  border: 2px solid color("lightgray");
  height: 100%;
  margin-bottom: 3em;

  .views-field-field-hover-image {
    padding-bottom: 3em;
  }

  .views-field-title {
    padding: 42% 0em 0em;
    color: color("orange");
    text-transform: uppercase;
    backface-visibility: hidden;

    h3 {
      font-size: 1.2em;
      line-height: 2.25em;

      @include media($bp-landscape){
        font-size: 1.5em;
        line-height: 1.5em;
      }

      @include media($bp-smdesktop){
        line-height: 2.5em;
      }
    }

    @include media($bp-smdesktop) {
      padding-top: 40%;
    }
  }

  .views-field-title,
  .views-field-title > a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .views-field-title > a {
    z-index: 1000;
    text-indent: 200%;
    white-space: nowrap;
    opacity: 0;
  }

  @include media($bp-smdesktop) {
    margin: 0em 2em 3em;
  }
}

.foursquare {

   img {
     border-bottom: 2px solid color("lightgray");
     opacity: .9;
     transition: opacity 0.35s;
     

     @include media($bp-landscape) {
       width: 100%;
     }
   }

   .views-field-title::before {
     position: absolute;
     content: '';
     opacity: 1;
     transition: opacity 0.35s, transform 0.35s;
     transform: scale(0);
   }

   a {
     position: absolute;
     top: 0;
     right: 0;
     bottom: 0;
     left: 0;
   }
 }

.foursquare:hover {
  h3 {
    transform: translate3d(0, 0, 0);
  }

  .views-field-title::before {
    opacity: 1;
    transform: scale(1);
  }

  .views-field-title {
    background-color: color("darkgray");
    color: color("white");
    opacity: 0.5;
  }

  img {
    opacity: 0.9;
    background-color: color("white");
  }
}
