/* Products and Markets Landing Pages */
.landing-block {
	.view-content {
		display: inline-flex;
		flex-wrap: wrap;
		width: 100%;

		.grid-five {
			text-align: center;
		}
	}

	.view-markets-landing {
		.views-row-4 {
			img {
				max-width: 175px;
			}
		}
	}
}

/* Product Page - Desktop redesign week of 9/25/17 */

#main-wrapper .region-content .view {
		&.view-display-id-block_2 {
			padding: 0em;
		}
}

#block-views-markets-landing-block-2 {
	padding: 0em;

	.view-content {
		display: block;
	}

	.grid-five {
		width: 80%;
		text-align: right;
		margin: 0em 20% 0em 0em;
	}

	.views-field-nothing {
		margin-bottom: 25px;

		img {
			display: none;
		}
	}
	
	a {
		p.button {
			&:after {
				font-family: FontAwesome;
				font-size: 1em;
				font-weight: 600;
				line-height: 0.888em;
				content: "\f054";
				margin-left: 5px;
				color: color("orange");
			}
		}

		&:hover {
			p.button {
				color: color("orange");

				&:after {
					color: color("black");
				}
			}
		}
	}

	@include media($bp-landscape){
		.grid-five {
			width: 280px;
			margin: 0em auto;
		}

		p.button {
			font-size: 1.25em;
		}
	}

	@include media($bp-tablet) {
		.views-field-nothing {
			margin-bottom: 2em;
		}
	}


	@include media($bp-desktop) {
		.view-content {
			display: flex;

			.grid-five {
				display: flex;
				width: 100%;
				margin: 0em;

				.views-field-nothing {
					font-size: 2em;
					width: 100%;
					margin-bottom: 0em;

					a {	
						display: inline-flex;
						flex-wrap: wrap;
						align-items: flex-start;
						width: 100%;
						height: 324px;

						p.button {
							font-size: 1em;
							line-height: 1em;
							width: 50%;
							text-align: right;
						}

						&:hover {
							img {
								display: block;
								width: 50%;
								max-width: 473px;
							}
						}
					}
				}

				&.views-row-2 {
					margin-top: -14em;
					.views-field-nothing {
						a {
							&:hover {
								img {
									margin-top: -3em;
								}
							}
						}
					}
				}

				&.views-row-3 {
					margin-top: -14em;
					.views-field-nothing {
						a {
							&:hover {
								img {
									margin-top: -5em;
								}
							}
						}
					}
				}

				&.views-row-4 {
					margin-top: -14em;

					.views-field-nothing {
						a {
							&:hover {
								img {
									margin-top: -7em;
								}
							}
						}
					}
				}

				&.views-row-5 {
					margin-top: -14em;

					.views-field-nothing {
						a {
							&:hover {
								img {
									margin-top: -9em;
								}
							}
						}
					}
				}

				/*omitted during design change, 9/28/17
				.views-field-field-prod-type-image {
					display: none;
				}*/
			}
		}
	}

	@include media($bp-lgdesktop){
		.grid-five {

			.views-field-nothing {
				a {
					height: 472px;

					&:hover {
						img {
							max-width: 708px;
						}
					}
				}
			}

			&.views-row-2 {
				margin-top: -22em;
			}

			&.views-row-3 {
				margin-top: -22em;
			}

			&.views-row-4 {
				margin-top: -22em;
			}

			&.views-row-5 {
				margin-top: -22em;
			}
		}
	}	
}

.page-node-8 {
	@include media($bp-desktop) {
		#bottom_nowrap {
			margin-top: -10em;
		}
	}

	@include media($bp-lgdesktop){
		#bottom_nowrap {
			margin-top: -10em;
		}
	}
}